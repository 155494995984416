import React, { useEffect, useState } from 'react';
import api from '../Api'; //Use this to make API calls (e.g. await api.get("/endpoint"))

const Messages = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await api.get("/");
                setMessages(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchMessages();
    }, []);

    return (
        <div>
            {messages.map((message, index) => (
                <div key={index}>{message}</div>
            ))}
        </div>
    );
};

export default Messages;