import React from 'react';
import { Route as SwizzleRoute, Routes as SwizzleRoutes } from 'react-router-dom';
import SwizzleHomePage from './pages/SwizzleHomePage';
import SwizzlePrivateRoute from './SwizzlePrivateRoute';
import Messages from './pages/Messages';

const RouteList = () => {
  return (
    <SwizzleRoutes>
  <SwizzleRoute path="/" element={<SwizzleHomePage />} />
  <SwizzleRoute path="/messages" element={<Messages />} />
</SwizzleRoutes>
  )
};

export default RouteList;
